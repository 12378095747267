<h1 mat-dialog-title>Upload Revision</h1>
<div mat-dialog-content class="dropzone">
  <input
    type="file"
    appDragAndDrop
    (fileDropped)="onFileDropped($event)"
    #fileUploadDialog
    id="fileUploadDialog"
    name="fileUploadDialog"
    accept="video/*"
    [disabled]="true"
  />
  <span>Drag and drop your revised video here or browse files below</span>
</div>

<div class="upload-information" *ngIf="uploadFile">
  <div *ngIf="errorMessage">
    <span>{{ errorMessage }}</span>
    <ul>
      <li *ngFor="let reason of rejectionReasons">{{ reason }}</li>
    </ul>
  </div>
  <mat-progress-bar
    *ngIf="uploadFile.progress >= 0"
    mode="determinate"
    [value]="uploadFile.progress"
  ></mat-progress-bar>
</div>
<div class="flex space-between" mat-dialog-actions>
  <button
    mat-button
    color="warn"
    [disabled]="isUploading"
    (click)="onCancel()"
    cdkFocusInitial
  >
    Cancel
  </button>
  <button
    mat-raised-button
    color="primary"
    class="display-top"
    [disabled]="isUploading"
    (click)="onOpenFile()"
  >
    browse files
  </button>
</div>
