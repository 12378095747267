<div *ngIf="labels.length > 0; else labelViewmodel">
  <div class="flex label-container">
    <div
      class="flex-row label-element"
      *ngFor="let label of labels; last as isLast"
    >
      <span class="mat-body-2">{{ label }}</span>
      <span *ngIf="!isLast">,</span>
    </div>
  </div>
</div>
<ng-template #labelViewmodel>
  <div class="flex label-container">
    <div
      class="flex-row label-element"
      *ngFor="let label of labelsViewmodel; last as isLast"
    >
      <span class="mat-body-2">{{ label.name }} ({{ label.confidence }})</span>
      <span *ngIf="!isLast">,</span>
    </div>
  </div>
</ng-template>
