import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VideoViewModel } from 'src/app/viewmodels/videoViewModel';

@Component({
  selector: 'app-rejection-dialog',
  templateUrl: './rejection-dialog.component.html',
  styleUrls: ['./rejection-dialog.component.scss'],
})
export class RejectionDialogComponent {
  reason = new FormControl('', [Validators.required, Validators.minLength(10)]);

  constructor(
    private dialogRef: MatDialogRef<RejectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VideoViewModel
  ) {}

  onRejectVideo(): void {
    this.dialogRef.close(this.reason.value);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
