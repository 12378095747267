import { UserRoles } from '../../auth/user-roles';

const availableRoles = [
  UserRoles.Studio,
  UserRoles.Processor,
  UserRoles.Director,
  UserRoles.Renderer,
  UserRoles.Viewer,
];

const navigationRoles = [
  UserRoles.Director,
  UserRoles.Renderer,
  UserRoles.Root,
  UserRoles.Viewer,
];
const uploadRoles = [UserRoles.Studio, UserRoles.Root];
const deleteRoles = [UserRoles.Root];
const processingRoles = [UserRoles.Processor, UserRoles.Root];
const approvingRoles = [UserRoles.Director, UserRoles.Root];
const renderingRoles = [UserRoles.Renderer, UserRoles.Director, UserRoles.Root];
const sharingRoles = [UserRoles.Renderer, UserRoles.Director, UserRoles.Viewer, UserRoles.Root];
const statsRoles = [UserRoles.Director, UserRoles.Viewer, UserRoles.Root];

export {
  availableRoles,
  navigationRoles,
  uploadRoles,
  deleteRoles,
  processingRoles,
  approvingRoles,
  renderingRoles,
  sharingRoles,
  statsRoles
};
