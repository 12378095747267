import { User } from '../models/user.model';
import * as moment from 'moment';
import { getFormattedDate } from '../utils/date-util'

export class UserViewModel {

  creationTime: string;
  lastModificationTime: string;

  id: string;
  loginId: string;
  role: string;
  locked: boolean;
  lastLoginTime: string;

  isSelf: boolean;

  constructor(user: User, authenticated_user_id: string) {
    this.id = user.user_id;
    this.isSelf = user.user_id === authenticated_user_id;
    this.loginId = user.user_login_id;
    this.role = user.user_role;
    this.locked = user.user_locked;
    this.creationTime = user.creation_time ? getFormattedDate(moment(user.creation_time)) : '-';
    this.lastModificationTime = user.last_modification_time ? getFormattedDate(moment(user.last_modification_time)) : '-';
    this.lastLoginTime = user.user_last_login_time ? getFormattedDate(moment(user.user_last_login_time)) : 'never';
  }

  get lockState(): string {
    return this.locked ? 'locked' : 'active';
  }
}
