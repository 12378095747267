import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticatedUserViewmodel } from '../viewmodels/authenticated-user.viewmodel';
import { VideoStates } from '../models/video-states.enum';
import { approvingRoles, deleteRoles, processingRoles, uploadRoles } from '../utils/constants/roles';
import { AppRoutes } from '../utils/constants/routes.enum';

export interface AccesToken {
  access_token: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authenticatedUser: BehaviorSubject<AuthenticatedUserViewmodel> =
    new BehaviorSubject<AuthenticatedUserViewmodel>(undefined);

  constructor(
    private http: HttpClient,
    private router: Router,
    private jwtHelper: JwtHelperService
  ) {
    const token = sessionStorage.getItem('authToken');
    if (!jwtHelper.isTokenExpired(token)) {
      const user = this.jwtHelper.decodeToken(token);
      this.authenticatedUser.next(
        new AuthenticatedUserViewmodel(
          token,
          user.cube_role,
          user.cube_user_id,
          user.sub
        )
      );
    } else {
      sessionStorage.removeItem('authToken');
    }
  }

  login(
    username: string,
    password: string
  ): Observable<boolean | HttpErrorResponse> {
    return this.http
      .post<AccesToken>(`${environment.backendUrl}/auth/token`, {
        username: username,
        password: password,
      })
      .pipe(
        map((res) => {
          sessionStorage.setItem('authToken', res.access_token);
          const user = this.jwtHelper.decodeToken(res.access_token);
          this.authenticatedUser.next(
            new AuthenticatedUserViewmodel(
              res.access_token,
              user.cube_role,
              user.cube_user_id,
              user.sub
            )
          );
          this.router.navigate([AppRoutes.CurationVideos]);
          return true;
        }),
        catchError((err) => of(err))
      );
  }

  logout(): void {
    sessionStorage.removeItem('authToken');
    this.authenticatedUser.next(undefined);
    this.router.navigate([AppRoutes.Login]);
  }

  handleError(err: HttpErrorResponse, url?: AppRoutes): void {
    if (err.status === 401) {
      this.logout();
    } else if (err.status === 404) {
      this.router.navigate(['404', url])
    }
  }

  canUpload(): boolean {
    if (this.authenticatedUser.value === undefined) {
      return false;
    }
    return uploadRoles.includes(this.authenticatedUser.value.role);
  }

  canDelete(): boolean {
    if (this.authenticatedUser.value === undefined) {
      return false;
    }
    return deleteRoles.includes(this.authenticatedUser.value.role);
  }

  canProcess(): boolean {
    if (this.authenticatedUser.value === undefined) {
      return false;
    }
    return processingRoles.includes(this.authenticatedUser.value.role);
  }

  canApprove(): boolean {
    if (this.authenticatedUser.value === undefined) {
      return false;
    }
    return approvingRoles.includes(this.authenticatedUser.value.role);
  }

  hasPermissionForState(state: VideoStates): boolean {
    switch (state) {
      case VideoStates.new:
        return this.canProcess();
      case VideoStates.processing:
        return this.canProcess();
      case VideoStates.pending_approval:
        return this.canApprove() || this.canProcess();
      case VideoStates.approved:
        return this.canApprove();
      default:
        return false;
    }
  }
}
