import { Shot } from '../models/shot.model';

export class ShotListViewmodel {
  id: string;
  start: number;
  duration: string;
  labels: string[];
  creationTime: string;
  lastModificationTime: string;
  shotThumbnailSrc: string;
  videoId: string;
  hasOverflow: boolean;

  constructor(shot: Shot) {
    this.id = shot.shot_id;
    this.start = shot.shot_start;
    // FIXME: how can shot duration be undefined?
    this.duration = shot.shot_duration ? `${shot.shot_duration.toFixed(2)}s` : `N/A`;
    this.labels = shot.label_names;
    this.creationTime = shot.creation_time;
    this.lastModificationTime = shot.last_modification_time;
    this.shotThumbnailSrc = shot.shot_thumbnail_src;
    this.videoId = shot.video_id;
    this.hasOverflow = this.labels && this.labels.length > 2 ? true : false;
  }
}
