import { Clipboard } from '@angular/cdk/clipboard';
import { HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { VideoService } from 'src/app/services/video.service';
import { AuthService } from 'src/app/auth/auth.service';
import { VideoViewModel } from 'src/app/viewmodels/videoViewModel';
import { MAX_FRAME_WIDTH } from 'src/app/utils/constants/frame';
import { AuthenticatedUserViewmodel } from 'src/app/viewmodels/authenticated-user.viewmodel';
import { InstructionsDialogComponent } from 'src/app/modules/shared/dialogs/instructions-dialog/instructions-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/modules/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AppRoutes } from 'src/app/utils/constants/routes.enum';

@Component({
  selector: 'app-video-detail',
  templateUrl: './video-detail.component.html',
  styleUrls: ['./video-detail.component.scss'],
})
export class VideoDetailComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;

  uuid: string;
  video: VideoViewModel;
  videoUrl: SafeResourceUrl;
  videoOriginalUrl: SafeResourceUrl;
  isVideoTooWide: boolean;

  maxTabWidth: number = MAX_FRAME_WIDTH;

  googleClassifierData = undefined;
  amazonLabelClassifierData = undefined;
  amazonFaceClassifierData = undefined;

  user: AuthenticatedUserViewmodel;

  private routeSubscription: Subscription;
  private videoSubscription: Subscription;

  constructor(
    public auth: AuthService,
    private videoService: VideoService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private clipboard: Clipboard,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe((params) => {
      this.uuid = params['id'];
      this.fetchVideoDetail();
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.videoSubscription.unsubscribe();
  }

  fetchVideoDetail(): void {
    this.isLoading = true;
    if (this.videoSubscription) {
      this.videoSubscription.unsubscribe();
    }
    this.videoSubscription = this.videoService
      .getVideoDetail(this.uuid)
      .subscribe((data) => {
        this.video = new VideoViewModel(data);
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.video.src
        );
        this.videoOriginalUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.video.originalSrc
        );
        this.isVideoTooWide = this.maxTabWidth <= this.video.width;
      })
      .add(() => (this.isLoading = false));
  }

  fetchGoogleClassifierData(): void {
    this.isLoading = true;
    this.videoService
      .getGoogleClassifierData(this.uuid)
      .subscribe((classifiers) => {
        this.googleClassifierData = classifiers;
      })
      .add(() => (this.isLoading = false));
  }

  fetchAmazonLabelClassifierData(): void {
    this.isLoading = true;
    this.videoService
      .getAmazonLabelClassifierData(this.uuid)
      .subscribe((data) => {
        this.amazonLabelClassifierData = data;
      })
      .add(() => (this.isLoading = false));
  }

  fetchAmazonFaceClassifierData(): void {
    this.isLoading = true;
    this.videoService
      .getAmazonFaceClassifierData(this.uuid)
      .subscribe((data) => {
        this.amazonFaceClassifierData = data;
      })
      .add(() => (this.isLoading = false));
  }

  onDownload(): void {
    this.isLoading = true;
    this.videoService
      .getDownloadLink(this.video.id)
      .subscribe((res: { url: string }) => {
        const a = document.createElement('a');
        a.href = res.url;
        a.click();
      })
      .add(() => (this.isLoading = false));
  }

  onRegenerateFramePreviews(): void {
    this.videoService.regenerateVideoPreviews(this.video.id).subscribe((res) => {
      if (res.status === 204) {
        this.snackBar.open('Regenerating frame previews, this will take a few seconds');
      } else {
        this.snackBar.open('Regenerating frame previews failed');
      }
    });
  }

  onRegenerateData(event: MouseEvent): void {
    event.stopPropagation();
    this.isLoading = true;
    this.videoService
      .regenerateClassifierData(this.video.id)
      .subscribe((res) => {
        if (res.status === 200) {
          this.snackBar.open(
            'Regenerating classifier data triggered, data may take a few minutes to update'
          );
        } else {
          this.snackBar.open('Regenerating classifier data failed');
        }
      })
      .add(() => (this.isLoading = false));
  }

  onCopyGoogleClassifierData(event: MouseEvent) {
    event.stopPropagation();
    const data = JSON.stringify(this.googleClassifierData);
    this.clipboard.copy(data);
    this.snackBar.open('Copied Google classifier data to clipboard');
  }

  onOpenInstructions(): void {
    this.dialog.open(InstructionsDialogComponent);
  }

  onOpenGoogleClassifierDataSection(): void {
    if (!this.googleClassifierData) {
      this.fetchGoogleClassifierData();
    }
  }

  onDeleteVideo(): void {
    this.isLoading = true;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Are you sure?',
        text: `Do you really want to delete the video ${this.video.name}?`
      }
    });
    dialogRef
      .afterClosed()
      .subscribe(confirmed => {
        if (confirmed) {
          this.videoService.deleteVideo(this.video.id).subscribe((res) => {
            if (res.status === HttpStatusCode.NoContent) {
              this.snackBar.open(`Video ${this.video.name} deleted succesfully`);
              this.router.navigate([AppRoutes.CurationVideos]);
            } else {
              this.snackBar.open(`Video deletion failed unexpectedly`);
            }
          });
          // TODO: missing error case in subscribe
        }
      })
      .add(() => (this.isLoading = false));
  }
}
