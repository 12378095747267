import * as moment from 'moment';
import { Video } from '../models/video.model';
import { getFormattedDate } from '../utils/date-util';
import { formatFps, formatDuration } from '../utils/video-util';
import { ShotListViewmodel } from './shot-list.viewmodel';
import { formatSize } from '../utils/size-util';

export class VideoViewModel {
  id: string;
  assignedUser: string;
  name: string;
  creationTime: string;
  lastModificationTime: number;
  isExcluded: boolean;
  _bitrate: number;
  get bitrate(): string {
    return (this._bitrate / Math.pow(10, 3)).toFixed(2);
  }

  codecName: string;
  _fps: number;
  get fps(): string {
    return formatFps(this._fps);
  }
  _duration: number;
  get duration(): string {
    return formatDuration(this._duration);
  }

  height: number;
  width: number;
  _originalSize: number;
  get originalSize(): string {
    return formatSize(this._originalSize);
  }

  src: string;
  originalSrc: string;
  videoBarcodeSrc: string;
  state: string;
  previewFrames: string[];
  shots?: ShotListViewmodel[];
  rawData: any;
  reason?: string;

  get resolution(): string {
    return `${this.width}x${this.height}`;
  }

  constructor(public video: Video) {
    this.id = video.video_id;
    this.assignedUser = video.assigned_user_id;
    this.name = video.video_name;
    this.creationTime = getFormattedDate(moment(video.creation_time));
    this.lastModificationTime = video.last_modification_time;
    this.isExcluded = video.excluded_from_curation;
    this._bitrate = video.metadata_bitrate;
    this.codecName = video.metadata_codec_name;
    this._fps = video.metadata_fps;
    this._duration = video.metadata_duration;
    this.height = video.metadata_resolution_h;
    this.width = video.metadata_resolution_w;
    this._originalSize = video.video_original_size;
    this.src = video.video_src;
    this.originalSrc = video.video_original_src;
    this.videoBarcodeSrc = video.video_barcode_src;
    this.state = video.video_state;
    this.previewFrames = [
      video.video_preview_frame_1_src,
      video.video_preview_frame_2_src,
      video.video_preview_frame_3_src,
      video.video_preview_frame_4_src,
      video.video_preview_frame_5_src,
    ];
    this.shots = video.shots?.map((shot) => new ShotListViewmodel(shot));
    this.rawData = JSON.parse(video.metadata_raw);
    this.reason = video.video_reject_reason;
  }

  get hasPreviewFrames(): boolean {
    return this.previewFrames[0] !== null;
  }
}
