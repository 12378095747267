export enum AppRoutes {
  Login = '/login',
  CurationVideos = '/curation/videos',
  CurationVideosUpload = '/curation/videos/upload',
  Content = '/content',
  ContentShots = '/content/shots',
  ContentShotsByColor = '/content?mode=colors',
  ContentShotsByLabels = '/content?mode=labels',
  ContentVideosByLabels = '/content?mode=labels',
  Renderer = '/renderer',
  Director = '/director',
  FileSharing = '/file-sharing',
  NotFound = '/404',
}
