import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';

@Directive({
  selector: '[appHover]',
})
export class HoverDirective {
  @HostBinding('class.fileover') fileOver: boolean;
  el: HTMLElement;

  @Input() hoverElement: HTMLElement;

  constructor(el: ElementRef) {
    this.el = el.nativeElement;
  }

  @HostListener('mouseover', ['$event']) onHover(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if(this.el.children[1]) {
      this.el.children[1].setAttribute('style', 'display: block');
    }
    if(this.hoverElement) {
      this.hoverElement.classList.add('hovered');
    }
  }

  @HostListener('mouseout', ['$event']) onHoverEnd(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if(this.el.children[1]) {
      this.el.children[1].setAttribute('style', 'display: none');
    }
    if(this.hoverElement && this.hoverElement.classList.contains('hovered')) {
      this.hoverElement.classList.remove('hovered');
    }
  }
}
