<div class="container">

  <div class="button-container">
    <button mat-raised-button color=primary (click)="onAddUser()" [disabled]="busy">
      Add User
      <mat-icon class="btn-icon">add</mat-icon>
    </button>
  </div>

  <p class="mat-body-1">{{ users.length }} users found.</p>

  <div class="card-container">

    <mat-card *ngFor="let user of users; last as last">

      <mat-card-title-group>
        <mat-card-title>{{ user.loginId }}</mat-card-title>
        <mat-card-subtitle>{{ user.role }}</mat-card-subtitle>
        <mat-icon mat-card-avatar>person</mat-icon>
      </mat-card-title-group>
      <mat-card-content>
        <p class="mat-body-2">{{ user.lockState }}</p>
        <p class="mat-small">
        Last login:<br>
        {{ user.lastLoginTime }}
        </p>
      </mat-card-content>
      <mat-card-actions>
        <button *ngIf="user.locked" (click)="unlockUser(user)" [disabled]="user.isSelf" mat-button>Unlock</button>
        <button *ngIf="!user.locked" (click)="lockUser(user)" [disabled]="user.isSelf" mat-button>Lock</button>
        <button mat-button (click)="onSetPassword(user)">Set Password</button>
      </mat-card-actions>

    </mat-card>

  </div>

</div>

