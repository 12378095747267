import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-size-preview',
  templateUrl: './size-preview.component.html',
  styleUrls: ['./size-preview.component.scss'],
})
export class SizePreviewComponent {
  @Input() resolutionWidth: number = 1440;
  @Input() resolutionHeight: number = 1440;

  constructor() {}
}
