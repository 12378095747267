import { AuthGuard } from 'src/app/auth/auth.guard';
import { VideoDetailComponent } from './video-detail/video-detail.component';
import { VideoListComponent } from './video-list/video-list.component';
import { VideoUploadComponent } from './video-upload/video-upload.component';

export const VideosRoutes = [
  { path: 'videos', component: VideoListComponent, canActivate: [AuthGuard] },
  {
    path: 'videos/upload',
    component: VideoUploadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'videos/:id',
    component: VideoDetailComponent,
    canActivate: [AuthGuard],
  }
];
