export enum VideoStates {
  new = 'New',
  processing = 'Processing',
  pending_approval = 'Pending Approval',
  approved = 'Approved',
}

export enum VideoIndexState {
  new = 0,
  processing = 1,
  pending_approval = 2,
  approved = 3,
}
