import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideosRoutes as routes } from './curation.routes';
import { VideoDetailComponent } from './video-detail/video-detail.component';
import { VideoListComponent } from './video-list/video-list.component';
import { VideoUploadComponent } from './video-upload/video-upload.component';
import { FlowIndicatorComponent } from './video-detail/flow-indicator/flow-indicator.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    VideoDetailComponent,
    VideoListComponent,
    VideoUploadComponent,
    FlowIndicatorComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    NgxJsonViewerModule,
    RouterModule.forChild(routes),
  ],
})
export class CurationModule {}
