import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { mergeMap } from 'rxjs/operators';
import { FileUploadViewModel } from 'src/app/viewmodels/file-upload-view-model';
import { VideoService } from 'src/app/services/video.service';
import { VideoViewModel } from 'src/app/viewmodels/videoViewModel';
import { MultipartUploader } from '../../../../utils/multipart-uploader';
import { MultipartUploadService, MultipartUploadTarget } from '../../../../services/multipart-upload.service';

@Component({
  selector: 'app-revise-video-dialog',
  templateUrl: './revise-video-dialog.component.html',
  styleUrls: ['./revise-video-dialog.component.scss'],
})
export class ReviseVideoDialogComponent implements OnInit {
  @ViewChild('fileUploadDialog', { static: true }) fileUpload: ElementRef;

  uploadFile: FileUploadViewModel;
  isUploading: boolean = false;
  progress: number = 0;

  errorMessage: string;
  rejectionReasons: string[] = [];

  private multipartUploader: MultipartUploader;

  constructor(
    private dialogRef: MatDialogRef<ReviseVideoDialogComponent>,
    private videoService: VideoService,
    private multipartUploadService: MultipartUploadService,
    private snackBar: MatSnackBar,
    private httpClient: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: VideoViewModel
  ) {}

  ngOnInit(): void {
    this.fileUpload.nativeElement.addEventListener('change', () => {
      const fileUpload = this.fileUpload.nativeElement;
      const file = fileUpload.files[0];
      this.uploadFile = { data: file, inProgress: false, progress: 0 };
      this.onUpload(this.uploadFile);
    });
  }

  onFileDropped(files): void {
    if (files.length === 1 && !this.isUploading) {
      this.uploadFile = { data: files[0], inProgress: false, progress: 0 };
      this.onUpload(this.uploadFile);
    } else if (this.isUploading) {
      this.snackBar.open(
        'Upload already running, please wait until the current upload is done.'
      );
    } else {
      this.snackBar.open('Upload for multiple files is not supported');
    }
  }

  onOpenFile(): void {
    this.fileUpload.nativeElement.value = '';
    this.fileUpload.nativeElement.disabled = false;
    this.fileUpload.nativeElement.click();
    this.fileUpload.nativeElement.disabled = true;
  }

  onUpload(viewModel: FileUploadViewModel): void {

    if (!viewModel.data.name.includes(this.data.id)) {
      this.errorMessage = `Filename does not contain video's ID, make sure to include the ID in the filename`;
      return;
    }

    this.errorMessage = undefined;
    this.isUploading = true;
    this.dialogRef.disableClose = true;
    this.multipartUploader = new MultipartUploader(
      this.multipartUploadService, MultipartUploadTarget.Curation, this.httpClient, viewModel.data, 25_000_000);
    viewModel.inProgress = true;
    this.multipartUploader.progress$.subscribe((value) => {
      this.progress = value;
    });

    this.multipartUploader.upload().pipe(
      mergeMap((key) => {
        // After the MPU completes, signal to the backend that this object should be used as revision
        return this.videoService.reviseVideoWithS3Object(this.data.id, key)
      })
    ).subscribe({
      next: () => {
        this.snackBar.open('Revision uploaded successfully');
        this.isUploading = false;
        viewModel.inProgress = false;
        this.dialogRef.close(true);
      },
      error: err => {
        this.isUploading = false;
        viewModel.inProgress = false;
        this.errorMessage = `Failed to upload revision: ${err}`;
      }
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
