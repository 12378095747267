<div class="flex login">
  <mat-card class="login-container">
    <mat-card-header class="align-center">
      <mat-card-title>Sign in</mat-card-title>
      <mat-card-subtitle>Authorized personnel only</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="loginForm">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>
            <mat-icon>person</mat-icon>
            Username
          </mat-label>
          <input formControlName="username" type="text" matInput />
        </mat-form-field>
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>
            <mat-icon>lock</mat-icon>
            Password
          </mat-label>
          <input formControlName="password" type="password" matInput />
        </mat-form-field>
        <span class="error-message">{{ errorMessage }}</span>
        <button class="w-full login-button" mat-raised-button color="primary"
          [disabled]="loginForm.invalid || this.isLoading" (click)="login()">
          Sign in
        </button>
      </form>
    </mat-card-content>
    <mat-card-actions class="align-center">
      <p class="mat-caption">
        {{ appVersion }}<br>
        Built and operated by <a href="https://pixelverse.ch">Pixelverse LLC</a>
      </p>
    </mat-card-actions>
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
  </mat-card>
</div>
