import { Component, Input, OnInit } from '@angular/core';
import { LabelDetailViewmodel } from 'src/app/viewmodels/label-detail.viewmodel';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnInit {
  @Input() labels: string[] = [];
  @Input() labelsViewmodel: LabelDetailViewmodel[] = [];

  constructor() {}

  ngOnInit(): void {
    if (!this.labels) {
      this.labels = [];
    }
  }
}
