import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { VersionService } from 'src/app/services/version.service';
import { InstructionsDialogComponent } from 'src/app/modules/shared/dialogs/instructions-dialog/instructions-dialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  appVersion: string;

  isLoading = false;
  errorMessage: string = '';
  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  get passwordControl(): FormControl {
    const passwordControl = this.loginForm.get('password');
    if (passwordControl) {
      return passwordControl as FormControl;
    } else {
      return new FormControl();
    }
  }

  constructor(
    private auth: AuthService,
    private versionService: VersionService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.appVersion = this.versionService.appVersion();
  }

  login(): void {
    this.isLoading = true;
    const username = (this.loginForm.get('username') as FormControl).value;
    const password = this.passwordControl.value;
    this.auth.login(username, password).subscribe((message) => {
      this.isLoading = false;
      if (message !== true) {
        const error = message as HttpErrorResponse;
        if (error.status === HttpStatusCode.Unauthorized) {
          this.errorMessage =
            'Username or password is incorrect. Please check your inputs and try again';
        } else {
          this.errorMessage = `Unexpected error: ${error.status}, ${error.statusText}`;
        }
      }
    });
  }
}
