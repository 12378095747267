import * as moment from 'moment';
import { Video } from '../models/video.model';
import { getFormattedDate } from '../utils/date-util';
import { formatFps, formatDuration } from '../utils/video-util';
import { formatSize } from '../utils/size-util';

export class VideoListViewmodel {
  uuid: string;
  name: string;
  _length: number;
  get length(): string {
    return formatDuration(this._length);
  }

  _fps: number;
  get fps(): string {
    return formatFps(this._fps);
  }
  bitrate: number;
  _originalSize: number;
  get originalSize(): string {
    return formatSize(this._originalSize);
  }

  resolutionWidth: number;
  resolutionHeight: number;
  state: string;
  creationTime: string;
  lastModificationTime: number;
  rejectReason: string;

  get resolution(): string {
    return `${this.resolutionWidth}x${this.resolutionHeight}`;
  }

  constructor(video: Video) {
    this.uuid = video.video_id;
    this.name = video.video_name;
    this._length = video.metadata_duration;
    this._fps = video.metadata_fps;
    this.bitrate = video.metadata_bitrate;
    this._originalSize = video.video_original_size;
    this.resolutionWidth = video.metadata_resolution_w;
    this.resolutionHeight = video.metadata_resolution_h;
    this.state = video.video_state;
    this.rejectReason = video.video_reject_reason;
    this.creationTime = getFormattedDate(moment(video.creation_time));
    this.lastModificationTime = video.last_modification_time;
  }
}
