import { UserRoles } from '../auth/user-roles';

export class AuthenticatedUserViewmodel {
  token: string;
  role: UserRoles;
  userId: string;
  name: string;

  constructor(token: string, role: UserRoles, userId: string, name: string) {
    this.token = token;
    this.role = role;
    this.userId = userId;
    this.name = name;
  }
}
