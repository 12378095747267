import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { AuthService } from 'src/app/auth/auth.service';
import { AuthenticatedUserViewmodel } from 'src/app/viewmodels/authenticated-user.viewmodel';
import { VideoIndexState } from 'src/app/models/video-states.enum';
import { VideoService } from 'src/app/services/video.service';
import { VideoViewModel } from 'src/app/viewmodels/videoViewModel';
import { RejectionDialogComponent } from 'src/app/modules/shared/dialogs/rejection-dialog/rejection-dialog.component';
import { ReviseVideoDialogComponent } from '../../../shared/dialogs/revise-video-dialog/revise-video-dialog.component';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/utils/constants/routes.enum';

@Component({
  selector: 'app-flow-indicator',
  templateUrl: './flow-indicator.component.html',
  styleUrls: ['./flow-indicator.component.scss'],
})
export class FlowIndicatorComponent implements OnInit {
  @Input() video: VideoViewModel;
  @Input() busy: boolean;

  user: AuthenticatedUserViewmodel;

  stateIndexes = VideoIndexState;
  isLoading: boolean = false;

  @Output() flowChanged: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('flowControls') stepper: MatStepper;

  constructor(
    public auth: AuthService,
    private videoService: VideoService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.auth.authenticatedUser.subscribe((user) => {
      this.user = user;
    });
  }

  onPromoteVideo(): void {
    this.isLoading = true;
    this.videoService.promoteVideo(this.video.id).subscribe({
      next: () => {
        this.snackBar.open('Promoted video successfully');
        this.isLoading = false;
        this.flowChanged.emit('promoted');
      },
      error: (err) => {
        this.snackBar.open(`Failed to promote video ${err.message}`);
        this.isLoading = false;
      },
    });
  }

  onClaimVideo(): void {
    this.isLoading = true;
    this.videoService.claimVideo(this.video.id).subscribe({
      next: (res) => {
        this.stepper.selected.completed = true;
        this.isLoading = false;
        this.stepper.next();
        this.flowChanged.emit('claimed');
        this.snackBar.open('Claimed video successfully');
      },
      error: (err) => {
        this.snackBar.open(`Failed to claim video ${err.message}`);
      },
    });
  }

  onReleaseVideo(): void {
    this.videoService.releaseVideo(this.video.id).subscribe({
      next: () => {
        this.stepper.reset();
        this.snackBar.open(`Video released successfully`);
        this.flowChanged.emit('released');
      },
      error: (err) => {
        this.snackBar.open('Could not release video, please try again');
      },
    });
  }

  onReviseVideo(): void {
    const dialogRef = this.dialog.open(ReviseVideoDialogComponent, {
      data: this.video,
    });
    dialogRef.afterClosed().subscribe((wasSuccessful) => {
      if (wasSuccessful) {
        this.stepper.selected.completed = true;
        this.stepper.next();
        this.flowChanged.emit('revised');
      }
    });
  }

  onApproveRevision(): void {
    this.videoService.approveVideo(this.video.id).subscribe({
      next: () => {
        this.stepper.selected.completed = true;
        this.stepper.next();
        this.router.navigate([AppRoutes.CurationVideos]);
        this.snackBar
          .open('Approved video successfully', 'Back To Video')
          .afterDismissed()
          .subscribe((dismiss) => {
            if (dismiss.dismissedByAction) {
              this.router.navigate([AppRoutes.CurationVideos, this.video.id]);
            }
          });
      },
      error: (err) => {
        this.snackBar.open('Failed to approve video, please try again.');
      },
    });
  }

  onRejectRevision(): void {
    const dialogRef = this.dialog.open(RejectionDialogComponent, {
      data: this.video,
    });
    dialogRef.afterClosed().subscribe((reason) => {
      if (reason) {
        const formData = new FormData();
        formData.append('reason', reason);
        this.videoService.rejectVideo(this.video.id, formData).subscribe({
          next: () => {
            // enable editable property on 2nd step to enable a transition backwards
            this.stepper.steps.get(1).editable = true;
            this.stepper.previous();
            this.stepper.steps.get(1).editable = false;
            this.flowChanged.emit('rejected');
            this.snackBar.open('Rejected video successfully');
          },
          error: (err) => {
            this.snackBar.open('Rejection cancelled');
          },
        });
      }
    });
  }
}
