<h2 mat-dialog-title>Add new user</h2>

<mat-dialog-content>
  <form [formGroup]="formGroup">
    <mat-form-field class="w-full" appearance="fill">
      <mat-label>Login ID</mat-label>
      <input formControlName="loginId" matInput type="text">
    </mat-form-field>

    <mat-form-field class="w-full" appearance="fill">
      <mat-label>Role</mat-label>
      <mat-select formControlName="role">
        <mat-option *ngFor="let role of availableRoles" [value]="role">
          {{ role }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-full" appearance="fill">
      <mat-label>Password (min. 8 chars)</mat-label>
      <input formControlName="password" matInput type="password">
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button [disabled]="busy" mat-button (click)="onCancel()" cdkFocusInitial>Cancel</button>
  <button mat-button color="warn" [disabled]="!formGroup.valid || busy" (click)="onAddUser()">
    Add user
  </button>
</mat-dialog-actions>
