<h1 mat-dialog-title>
  Instructions
  <p class="mat-caption">
    I am also available within the app behind the
    <mat-icon>help</mat-icon> button
  </p>
</h1>
<div mat-dialog-content>
  <p class="justify-text mat-body-1">
    The flow chart below shows the lifecycle of a video in the curation process.
    Videos can be filtered by their curation state (e.g. New, Pending Approval), and can also be searched for by name.
  </p>
  <p>
    Please note that most actions are only available to users with specific roles. For instance, only a user
    that has the <i>studio</i> role may upload videos, and only a <i>processor</i> may claim a video and upload a new
    revision. Processed videos are checked by a <i>director</i> and then approved or rejected.
  </p>
  <p>
    If you happen to have any questions or feedback please write to
    <a href="mailto:mail@mariowinkler.ch">mail@mariowinkler.ch</a> or use the
    button at the bottom.
  </p>
  <p>
    Happy curating!
  </p>
  <!-- <video
    src="https://s3-eu-west-1.amazonaws.com/curator-videos-dev/originals/e72b9148-edf1-4898-8dce-69ed9788ff90"
    controls
    allowfullscreen
    width="560"
    height="315"
  ></video> -->
  <div class="image">
    <img
      src="../../../../assets/img/flow_chart.png"
      alt="Video curator flow chart"
    />
  </div>
</div>
<div class="space-between" mat-dialog-actions>
  <a href="mailto:mail@mariowinkler.ch" mat-button color="primary">
    Write email
  </a>
  <button mat-button color="primary" (click)="onClose()" cdkFocusInitial>Got It!</button>
</div>
