import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserRepository } from '../../../../services/user.repository';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-set-password-dialog',
  templateUrl: './set-password-dialog.component.html',
  styleUrls: ['./set-password-dialog.component.scss'],
})
export class SetPasswordDialogComponent implements OnInit {

  busy: boolean = false;
  password = new FormControl('', [Validators.required, Validators.minLength(8)]);

  constructor(
    private userRepository: UserRepository,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<SetPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public userId: string
  ) {
  }

  ngOnInit(): void {
    this.dialogRef.addPanelClass('dialog-panel-small');
  }

  onSetPassword(): void {
    this.busy = true;
    this.userRepository.setPassword(this.userId, this.password.value).subscribe({
      next: () => {
        this.snackBar.open(`Successfully set new password for user`);
        this.busy = false;
        this.dialogRef.close();
      },
      error: (err: HttpErrorResponse) => {
        this.snackBar.open(`Failed to set password (${err.message})`);
        this.busy = false;
      }
    })
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
