<div class="container">
  <div class="action-container">
    <div class="form-field">
      <mat-form-field appearance="fill">
        <mat-label>Search</mat-label>
        <input matInput [formControl]="search" (keyup.enter)="onSearch()" />
        <span
          *ngIf="!isSearchSubmitted(); else clear"
          class="link"
          (click)="onSearch()"
          matSuffix
        >
          <mat-icon>search</mat-icon>
        </span>
        <ng-template #clear>
          <span class="link" (click)="onClearSearch()" matSuffix>
            <mat-icon>clear</mat-icon>
          </span>
        </ng-template>
      </mat-form-field>
    </div>
    <div class="chip-container">
      <mat-chip-list>
        <mat-chip
          *ngFor="let state of stateKeys"
          [selected]="activeState === state"
          (click)="onStateSelection(state)"
          color="primary"
          [disabled]="disabledSelectionButtons.get(state)"
          >{{ states[state] }}</mat-chip
        >
      </mat-chip-list>
      <mat-chip
        class="my-videos-chip"
        [selected]="isOnlyMyVideos"
        (click)="onShowOnlyMyVideos()"
        color="accent"
        >Only My Videos
      </mat-chip>
      <mat-chip
        *ngIf="auth.canUpload()"
        class="excluded-chip"
        [selected]="isExcluded"
        (click)="onShowExcluded()"
        color="warn"
        >Excluded
      </mat-chip>
    </div>
    <div class="button-container">
      <button
        [disabled]="!auth.canUpload()"
        (click)="onNavigateUpload()"
        color="primary"
        mat-raised-button
      >
        Add Video(s)
        <mat-icon class="btn-icon">add</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="noItems !== -1 && totalDuration">
    <h4 class="text-gray">Total Videos: {{ noItems }} ({{ totalDuration }})</h4>
  </div>

  <div *ngIf="noItems !== 0 && noItems; else noData">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8 w-full"
    >
      <!-- Name column -->
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          sortActionDescription="Sort by name"
        >
          Name
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
          <mat-icon
            class="rejection-icon"
            color="warn"
            *ngIf="element.rejectReason"
            [matTooltip]="element.rejectReason"
            >warning</mat-icon
          >
        </td>
      </ng-container>

      <!-- Length column -->
      <ng-container matColumnDef="length">
        <th
          mat-header-cell
          *matHeaderCellDef
          sortActionDescription="Sort by length"
        >
          Duration
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.length }}
        </td>
      </ng-container>

      <!-- FPS column -->
      <ng-container matColumnDef="fps">
        <th
          mat-header-cell
          *matHeaderCellDef
          sortActionDescription="Sort by size"
        >
          FPS
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.fps }}
        </td>
      </ng-container>

      <!-- Size column -->
      <ng-container matColumnDef="originalSize">
        <th
          mat-header-cell
          *matHeaderCellDef
          sortActionDescription="Sort by size"
        >
          Size
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.originalSize }}
        </td>
      </ng-container>

      <!-- resolution column -->
      <ng-container matColumnDef="resolution">
        <th
          mat-header-cell
          *matHeaderCellDef
          sortActionDescription="Sort by resolution"
        >
          Resolution
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.resolution }}
        </td>
      </ng-container>

      <!-- Creation Time column -->
      <ng-container matColumnDef="creationTime">
        <th
          mat-header-cell
          *matHeaderCellDef
          sortActionDescription="Sort by resolution"
        >
          Creation
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.creationTime }}
        </td>
      </ng-container>

      <!-- Last Modification column -->
      <ng-container matColumnDef="lastModificationTime">
        <th
          mat-header-cell
          *matHeaderCellDef
          sortActionDescription="Sort by resolution"
        >
          Last Modification
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.lastModificationTime }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="onNavigateDetails(row)"
      ></tr>
    </table>
    <mat-paginator
      class="mat-elevation-z8"
      [length]="noItems"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [pageSizeOptions]="[10, 20, 50, 100]"
      showFirstLastButtons="true"
      (page)="updatePage($event)"
    ></mat-paginator>
  </div>
  <ng-template #noData>
    <div class="flex-column align-center justify-center">
      <img
        style="height: 50vh; margin-bottom: 2rem"
        [src]="imageUrl"
        alt="No Data was found"
      />
      <span *ngIf="noItems === 0" class="mat-headline">
        No data was found for your search query. Please try different search parameters.
      </span>
      <div *ngIf="noItems === undefined">
        <span class="mat-headline">
          There was an error fetching the videos.
        </span>
        <p class="mat-subheading-2" style="text-align: center">
          If this persists, try to get in touch with a
          <a href="mailto:support@classycode.com">developer</a>
        </p>
      </div>
    </div>
  </ng-template>
</div>
