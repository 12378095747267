<h2 mat-dialog-title>Set password</h2>

<mat-dialog-content>
  <mat-form-field class="w-full" appearance="fill">
    <mat-label>New password (min. 8 chars)</mat-label>
    <input [formControl]="password" matInput type="password">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button [disabled]="busy" mat-button (click)="onCancel()" cdkFocusInitial>Cancel</button>
  <button mat-button color="warn" [disabled]="!password.valid || busy" (click)="onSetPassword()">
    Set password
  </button>
</mat-dialog-actions>
