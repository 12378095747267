export enum ApiRoutes {
  CURATION = '/curation/videos',
  CLASSIFIERS = '/classifiers',
  CONTENT_SHOTS = '/content/shots',
  CONTENT_SHOTS_COLOR = '/content/shots/color',
  CONTENT_SHOTS_SEMANTIC = '/content/shots/semantic',
  SHARING_FILES = '/sharing/files',
  RENDER = '/render',
  DIRECTOR_JOBS = '/director/jobs',
  DIRECTOR_GPT4 = '/director/gpt4',
  STATS = '/stats',
}
