import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { VideoPage, Video } from '../models/video.model';
import { ApiRoutes } from '../utils/constants/api.enum';
import { AppRoutes } from '../utils/constants/routes.enum';

export interface QueryConfig {
  startIndex: number;
  videoLimit: number;
  stateFilter: string;
  nameFilter: string;
  userId: string;
  showExcluded: boolean;
}

export interface VideoIdResponse {
  video_id: string;
}

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  videoPreviews: BehaviorSubject<Video[]> = new BehaviorSubject<Video[]>([]);
  queryConfig: BehaviorSubject<QueryConfig> = new BehaviorSubject<QueryConfig>({
    startIndex: 0,
    videoLimit: 20,
    stateFilter: 'new',
    nameFilter: '',
    userId: '',
    showExcluded: false,
  });

  videoCount: number = -1;
  totalDuration: number = 0;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private jwtHelper: JwtHelperService
  ) {
    this.auth.authenticatedUser.subscribe((user) => {
      if (!jwtHelper.isTokenExpired()) {
        if (user.role === 'director') {
          this.queryConfig.next({
            ...this.queryConfig.value,
            stateFilter: 'pending_approval',
          });
        } else if (user.role === 'renderer') {
          this.queryConfig.next({
            ...this.queryConfig.value,
            stateFilter: 'approved',
          });
        } else {
          this.queryConfig.next({
            ...this.queryConfig.value,
            stateFilter: 'new',
          });
        }
      } else {
        this.queryConfig.next({
          startIndex: 0,
          videoLimit: 20,
          stateFilter: 'new',
          nameFilter: '',
          userId: '',
          showExcluded: false,
        });
      }
    });
  }

  upload(formData: FormData): Observable<HttpEvent<VideoIdResponse>> {
    return this.http.post<VideoIdResponse>(
      `${environment.backendUrl}${ApiRoutes.CURATION}/upload`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  setPreviewRange(startIndex: number, videoLimit: number): void {
    this.queryConfig.next({
      ...this.queryConfig.value,
      startIndex: startIndex,
      videoLimit: videoLimit,
    });
    this.getVideoPreviews();
  }

  setStateFilter(filterState: string): void {
    this.queryConfig.next({
      ...this.queryConfig.value,
      startIndex: 0,
      stateFilter: filterState,
    });
    this.getVideoPreviews();
  }

  setNameFilter(nameFilter: string): void {
    this.queryConfig.next({
      ...this.queryConfig.value,
      nameFilter: nameFilter,
    });
    this.getVideoPreviews();
  }

  setUserId(userId: string): void {
    this.queryConfig.next({
      ...this.queryConfig.value,
      userId: userId,
    });
    this.getVideoPreviews();
  }

  setShowExcluded(isExcluded: boolean): void {
    this.queryConfig.next({
      ...this.queryConfig.value,
      showExcluded: isExcluded,
    });
    this.getVideoPreviews();
  }

  refreshVideoPreviews(): void {
    this.getVideoPreviews();
  }

  private getVideoPreviews(): void {
    const config = this.queryConfig.value;
    this.http
      .get<VideoPage>(
        `${environment.backendUrl}${ApiRoutes.CURATION}` +
          `?offset=${config.startIndex}` +
          `&limit=${config.videoLimit}` +
          `&state=${config.stateFilter}` +
          `${config.nameFilter ? `&name=${config.nameFilter}` : ''}` +
          `${config.userId ? `&assigned_user_id=${config.userId}` : ''}` +
          `${config.showExcluded ? `&excluded=${config.showExcluded}` : ''}`
      )
      .pipe(
        catchError((err) => {
          this.auth.handleError(err)
          return of(err);
        })
      )
      .subscribe((data) => {
        this.videoCount = data.total_count;
        this.totalDuration = data.total_duration;
        this.videoPreviews.next(data.items);
      });
  }

  getVideoDetail(uuid: string): Observable<Video> {
    return this.http
      .get<Video>(`${environment.backendUrl}${ApiRoutes.CURATION}/${uuid}`)
      .pipe(
        catchError((err) => {
          this.auth.handleError(err, AppRoutes.CurationVideos)
          return of(err);
        })
      );
  }

  deleteVideo(uuid: string): Observable<any> {
    return this.http.delete(`${environment.backendUrl}${ApiRoutes.CURATION}/${uuid}`, {
      observe: 'response',
    });
  }

  promoteVideo(id: string): Observable<Video> {
    return this.http
      .post<Video>(`${environment.backendUrl}${ApiRoutes.CURATION}/${id}/promote`, {})
      .pipe(
        catchError((err) => {
          this.auth.handleError(err)
          return of(err);
        })
      );
  }

  claimVideo(id: string): Observable<HttpResponse<Object>> {
    return this.http
      .post(
        `${environment.backendUrl}${ApiRoutes.CURATION}/${id}/claim`,
        {},
        { observe: 'response' }
      )
      .pipe(
        catchError((err) => {
          this.auth.handleError(err)
          return of(err);
        })
      );
  }

  releaseVideo(id: string): Observable<HttpResponse<Object>> {
    return this.http
      .post(
        `${environment.backendUrl}${ApiRoutes.CURATION}/${id}/release`,
        {},
        { observe: 'response' }
      )
      .pipe(
        catchError((err) => {
          this.auth.handleError(err)
          return of(err);
        })
      );
  }

  reviseVideo(
    id: string,
    formData: FormData
  ): Observable<HttpEvent<VideoIdResponse>> {
    return this.http.post<VideoIdResponse>(
      `${environment.backendUrl}${ApiRoutes.CURATION}/${id}/revise`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  /**
   * Variant of reviseVideo where the video was previously uploaded to S3 (through a multi-part upload)
   * @param id
   * @param key The key of the object in s3, e.g. 'multipart-uploads/<some generated string>'
   */
  reviseVideoWithS3Object(
    id: string,
    key: string
  ): Observable<HttpResponse<VideoIdResponse>> {
    const formData = new FormData();
    formData.append('s3_object_key', key);
    return this.http.post<VideoIdResponse>(`${environment.backendUrl}${ApiRoutes.CURATION}/${id}/revise`, formData, {
      observe: 'response'
    }).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse && err.status == 400) {
          let message = err.error?.message || 'Revised video not accepted (400)'
          const failedChecks: string[] = err.error?.failed_checks;
          if (failedChecks) {
            message += ` (failed checks: ${failedChecks.join(', ')})`
          }
          return throwError(message);
        }
        return throwError(err);
      })
    );
  }


  approveVideo(id: string): Observable<HttpResponse<Object>> {
    return this.http
      .post(
        `${environment.backendUrl}${ApiRoutes.CURATION}/${id}/approve`,
        {},
        {
          observe: 'response',
        }
      )
      .pipe(
        catchError((err) => {
          this.auth.handleError(err)
          return of(err);
        })
      );
  }

  rejectVideo(
    id: string,
    formData: FormData
  ): Observable<HttpResponse<Object>> {
    return this.http
      .post(`${environment.backendUrl}${ApiRoutes.CURATION}/${id}/reject`, formData, {
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          this.auth.handleError(err)
          return of(err);
        })
      );
  }

  getGoogleClassifierData(videoUuid: string): Observable<any> {
    return this.http
      .get(`${environment.backendUrl}${ApiRoutes.CLASSIFIERS}/google/${videoUuid}`)
      .pipe(
        catchError((err) => {
          this.auth.handleError(err);
          return of(err);
        })
      );
  }

  getAmazonLabelClassifierData(videoUuid: string): Observable<any> {
    return this.http
      .get(`${environment.backendUrl}${ApiRoutes.CLASSIFIERS}/amazon/${videoUuid}/label`)
      .pipe(
        catchError((err) => {
          this.auth.handleError(err);
          return of(err);
        })
      );
  }

  getAmazonFaceClassifierData(videoUuid: string): Observable<any> {
    return this.http
      .get(`${environment.backendUrl}${ApiRoutes.CLASSIFIERS}/amazon/${videoUuid}/face`)
      .pipe(
        catchError((err) => {
          this.auth.handleError(err);
          return of(err);
        })
      );
  }

  regenerateClassifierData(uuid: string): Observable<HttpResponse<Object>> {
    return this.http
      .post(
        `${environment.backendUrl}${ApiRoutes.CLASSIFIERS}/google/${uuid}/regenerate`,
        {},
        { observe: 'response' }
      )
      .pipe(
        catchError((err) => {
          this.auth.handleError(err)
          return of(err);
        })
      );
  }

  regenerateColorClassifierData(uuid: string): Observable<HttpResponse<Object>> {
    return this.http
      .post(
        `${environment.backendUrl}${ApiRoutes.CLASSIFIERS}/color/${uuid}/regenerate`,
        {},
        { observe: 'response' }
      )
      .pipe(
        catchError((err) => {
          this.auth.handleError(err)
          return of(err);
        })
      );
  }

  regenerateVideoPreviews(uuid: string): Observable<HttpResponse<Object>> {
    return this.http
      .post(`${environment.backendUrl}${ApiRoutes.CURATION}/${uuid}/regenerate_previews`, {}, { observe: 'response' })
      .pipe(
        catchError((err) => {
          this.auth.handleError(err)
          return of(err);
        })
      );
  }

  getDownloadLink(uuid: string): Observable<{ url: string }> {
    return this.http
      .get<{ url: string }>(
        `${environment.backendUrl}${ApiRoutes.CURATION}/${uuid}/download_url`
      )
      .pipe(
        catchError((err) => {
          this.auth.handleError(err)
          return of(err);
        })
      );
  }
}
