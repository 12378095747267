import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { VideoService } from 'src/app/services/video.service';
import { VideoStates } from 'src/app/models/video-states.enum';
import { PageEvent } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { AuthenticatedUserViewmodel } from 'src/app/viewmodels/authenticated-user.viewmodel';
import { VideoListViewmodel } from 'src/app/viewmodels/video-list.viewmodel';
import { formatDuration } from 'src/app/utils/video-util';
import { UserRoles } from 'src/app/auth/user-roles';
import { AppRoutes } from 'src/app/utils/constants/routes.enum';
import { parse as parseUuid } from 'uuid';

@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss'],
})
export class VideoListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'name',
    'length',
    'fps',
    'originalSize',
    'resolution',
    'creationTime',
  ];
  dataSource: MatTableDataSource<VideoListViewmodel> = new MatTableDataSource();

  // Pagination Properties
  pageIndex: number;
  pageSize: number;
  noItems: number;
  totalDuration: string;

  // State Pill Properties
  states = VideoStates; // Expose enum to template for access to diplay name
  stateKeys: string[] = Object.keys(VideoStates);
  activeState: string;
  disabledSelectionButtons: Map<string, boolean>;

  user: AuthenticatedUserViewmodel;
  isOnlyMyVideos: boolean;
  isExcluded: boolean;

  // Search Properties
  nameFilter: string;
  search: FormControl = new FormControl('', []);

  // Error handling properties
  imageUrl: string = 'assets/img/no-content-icon.svg';

  // Subscriptions
  private subscriptions: Subscription[] = [];

  constructor(
    public auth: AuthService,
    private videoService: VideoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.videoService.refreshVideoPreviews();
    this.subscriptions.push(
      this.videoService.videoPreviews.subscribe((data) => {
        this.noItems = this.videoService.videoCount;
        this.totalDuration = formatDuration(this.videoService.totalDuration);
        const previews = data.map((video) => new VideoListViewmodel(video));
        this.dataSource = new MatTableDataSource(previews);
        this.imageUrl = !this.noItems
          ? 'assets/img/no-content-icon.svg'
          : 'assets/img/no-data-icon.svg';
      })
    );
    this.subscriptions.push(
      this.videoService.queryConfig.subscribe((config) => {
        this.pageIndex = config.startIndex / config.videoLimit;
        this.pageSize = config.videoLimit;
        this.activeState = config.stateFilter;
        this.nameFilter = config.nameFilter;
        this.search.setValue(config.nameFilter);
        this.isOnlyMyVideos = config.userId !== '';
        this.isExcluded = config.showExcluded;
      })
    );
    this.subscriptions.push(
      this.auth.authenticatedUser.subscribe((user) => {
        this.user = user;
        this.disabledSelectionButtons = this.getDisabledSelectionButtons();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  getDisabledSelectionButtons(): Map<string, boolean> {
    const disabledSelButtonStates = new Map<string, boolean>();
    this.stateKeys.forEach((state) => {
      if (
        this.user &&
        this.user.role === UserRoles.Processor &&
        this.states[state] === this.states.approved
      ) {
        disabledSelButtonStates.set(state, true);
      } else {
        disabledSelButtonStates.set(state, false);
      }
    });
    return disabledSelButtonStates;
  }

  isSearchSubmitted(): boolean {
    return this.search.value === this.nameFilter && this.search.value !== '';
  }

  onSearch(): void {
    try {
      parseUuid(this.search.value)
      this.router.navigate([AppRoutes.CurationVideos, this.search.value]);
    } catch (e) {
      this.videoService.setNameFilter(this.search.value);
      this.videoService.setPreviewRange(0, this.pageSize);
    }
  }

  onClearSearch(): void {
    this.videoService.setNameFilter('');
  }

  onNavigateDetails(video: VideoListViewmodel): void {
    this.router.navigate([AppRoutes.CurationVideos, video.uuid]);
  }

  onNavigateUpload(): void {
    this.router.navigate([AppRoutes.CurationVideosUpload]);
  }

  onStateSelection(state: string): void {
    if (!this.disabledSelectionButtons.get(state)) {
      this.videoService.setStateFilter(state);
    }
  }

  onShowOnlyMyVideos(): void {
    this.isOnlyMyVideos = !this.isOnlyMyVideos;
    if (this.isOnlyMyVideos) {
      this.videoService.setUserId(this.user.userId);
    } else {
      this.videoService.setUserId('');
    }
  }

  onShowExcluded(): void {
    this.isExcluded = !this.isExcluded;
    this.videoService.setShowExcluded(this.isExcluded);
  }

  updatePage(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.videoService.setPreviewRange(
      this.pageIndex * this.pageSize,
      this.pageSize
    );
  }
}
