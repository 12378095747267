import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { UserRoles } from 'src/app/auth/user-roles';
import { AppRoutes } from 'src/app/utils/constants/routes.enum';
import { AuthenticatedUserViewmodel } from 'src/app/viewmodels/authenticated-user.viewmodel';
import { environment } from 'src/environments/environment';
import { statsRoles } from '../../utils/constants/roles';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  title = `Video Curator ${environment.production ? '' : '(Dev)'}`;
  user: AuthenticatedUserViewmodel;

  root = UserRoles.Root;

  adminVisible = false;
  statsVisible = false;

  // expose enum to template
  AppRoutes = AppRoutes;

  constructor(
    private router: Router,
    private auth: AuthService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.auth.authenticatedUser.subscribe((user) => {
      if (user) {
        this.user = user;
        this.adminVisible = user.role === UserRoles.Root;
        this.statsVisible = statsRoles.indexOf(user.role) !== -1;
      } else {
        this.user = undefined;
        this.adminVisible = false;
        this.statsVisible = false;
      }
    });
  }

  isLoginRoute(): boolean {
    return this.router.url === AppRoutes.Login;
  }

  isHomeRoute(): boolean {
    return (
      this.router.url === AppRoutes.CurationVideos ||
      this.router.url === AppRoutes.Content ||
      this.router.url === AppRoutes.ContentShotsByColor ||
      this.router.url === AppRoutes.ContentShotsByLabels ||
      this.router.url === AppRoutes.ContentVideosByLabels ||
      this.router.url === AppRoutes.Renderer ||
      this.router.url.startsWith(AppRoutes.NotFound)
    );
  }

  onNavigateHome(): void {
    this.location.back();
  }

  onNavigateAdmin(): void {
    this.router.navigateByUrl('/admin');
  }

  onNavigateStats(): void {
    this.router.navigateByUrl('/stats');
  }

  logout(): void {
    this.auth.logout();
  }
}
