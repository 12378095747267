import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { AdminComponent } from './components/admin/admin.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'curation',
    loadChildren: () =>
      import('./modules/curation/curation.module').then((m) => m.CurationModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'content',
    loadChildren: () =>
      import('./modules/content/content.module').then((m) => m.ContentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'file-sharing',
    loadChildren: () =>
      import('./modules/file-sharing/file-sharing.module').then((m) => m.FileSharingModule),
    canActivate: [AuthGuard],
  },
  {
     path: 'renderer',
     loadChildren: () => import('./modules/renderer/renderer.module').then(m => m.RendererModule),
     canActivate: [AuthGuard],
  },
  {
    path: 'director',
    loadChildren: () => import('./modules/director/director.module').then(m => m.DirectorModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'stats',
    loadChildren: () => import('./modules/stats/stats.module').then(m => m.StatsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '404/:category',
    component: NotFoundComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'curation/videos',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
