import { Component, OnInit } from '@angular/core';
import { UserRepository } from '../../services/user.repository';
import { UserViewModel } from '../../viewmodels/user.viewmodel';
import { HttpErrorResponse } from '@angular/common/http';
import { AddUserDialogComponent } from '../../modules/shared/dialogs/add-user-dialog/add-user-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../auth/auth.service';
import { SetPasswordDialogComponent } from 'src/app/modules/shared/dialogs/set-password-dialog/set-password-dialog.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  users: UserViewModel[] = [];
  busy: boolean = false;

  constructor(private userRepository: UserRepository,
              private authService: AuthService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,) {
  }

  ngOnInit(): void {
    this.loadUsers();
  }

  private loadUsers() {
    this.busy = true;
    const authenticatedUserId = this.authService.authenticatedUser.value?.userId ?? '';
    this.userRepository.getUsers().subscribe({
      next: users => {
        this.users = users.map(u => new UserViewModel(u, authenticatedUserId))
          .sort((a, b) => a.loginId.localeCompare(b.loginId));
        this.busy = false;
      }
    });
  }

  lockUser(user: UserViewModel): void {
    this.busy = true;
    this.userRepository.lockUser(user.id).subscribe({
      next: () => {
        this.snackBar.open(`Successfully locked user ${user.loginId}`);
        this.busy = false;
        this.loadUsers();
      },
      error: (err: HttpErrorResponse) => {
        this.snackBar.open(`Failed to lock user (${err.message})`);
        this.busy = false;
        this.loadUsers();
      }
    });
  }

  unlockUser(user: UserViewModel): void {
    this.busy = true;
    this.userRepository.unlockUser(user.id).subscribe({
      next: () => {
        this.snackBar.open(`Successfully unlocked user ${user.loginId}`);
        this.busy = false;
        this.loadUsers();
      },
      error: (err: HttpErrorResponse) => {
        this.snackBar.open(`Failed to unlock user (${err.message})`);
        this.busy = false;
        this.loadUsers();
      }
    });
  }

  onAddUser(): void {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {});
    dialogRef.afterClosed().subscribe(() => {
      this.loadUsers();
    });
  }

  onSetPassword(user: UserViewModel): void {
    const dialogRef = this.dialog.open(SetPasswordDialogComponent, {
      data: user.id
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadUsers();
    });
  }
}
