<mat-toolbar *ngIf="!isLoginRoute()" color="primary" class="toolbar">
  <button *ngIf="!isHomeRoute()" mat-icon-button (click)="onNavigateHome()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span class="page-title">{{ title }}</span>
  <div class="app-routes flex">
    <span
      class="app-routes-element link"
      [routerLink]="AppRoutes.CurationVideos"
      routerLinkActive="active-route"
    >
      Curation
    </span>
    <span
      class="app-routes-element link"
      [routerLink]="AppRoutes.Content"
      [routerLinkActive]="'active-route'"
    >
      Content
    </span>
    <span
      class="app-routes-element link"
      [routerLink]="AppRoutes.FileSharing"
      [routerLinkActive]="'active-route'"
    >
      Share
    </span>
    <span
      class="app-routes-element link"
      [routerLink]="AppRoutes.Director"
      [routerLinkActive]="'active-route'"
    >
      Director
    </span>
    <span
      class="app-routes-element link"
      [routerLink]="AppRoutes.Renderer"
      [routerLinkActive]="'active-route'"
    >
      Render
    </span>
  </div>
  <div *ngIf="user" appHover class="user-container">
    <span>
      <mat-icon>account_circle</mat-icon>
    </span>
    <div class="user-body-container">
      <mat-card>
        <mat-card-title> User: {{ user.name }} </mat-card-title>
        <mat-card-subtitle>Role: {{ user.role }} </mat-card-subtitle>
        <mat-card-content>
          <button mat-button *ngIf="adminVisible" (click)="onNavigateAdmin()">
            Admin <mat-icon>manage_accounts</mat-icon>
          </button>
          <button mat-button *ngIf="statsVisible" (click)="onNavigateStats()">
            Stats <mat-icon>bar_chart</mat-icon>
          </button>
          <button mat-button (click)="logout()">
            Logout
            <mat-icon class="btn-icon">logout</mat-icon>
          </button>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</mat-toolbar>
