<div *ngIf="video">
        <mat-stepper
          linear
          #flowControls
          [selectedIndex]="stateIndexes[video.state]"
          [disableRipple]="true"
        >
          <mat-step
            [completed]="stateIndexes[video.state] >= 1"
            editable="false"
          >
            <ng-template matStepLabel>New</ng-template>
            <div class="flow-actions-step">
              <button
                *ngIf="video.isExcluded"
                [disabled]="!auth.canUpload() || isLoading || busy"
                mat-raised-button
                color="accent"
                (click)="onPromoteVideo()"
              >
                Promote
                <mat-icon class="btn-icon">
                  switch_access_shortcut_add
                </mat-icon>
              </button>
              <button
                [disabled]="!auth.canProcess() || video.isExcluded || isLoading || busy"
                mat-raised-button
                color="accent"
                (click)="onClaimVideo()"
              >
                Claim
                <mat-icon class="btn-icon">person_add_alt</mat-icon>
              </button>
            </div>
          </mat-step>
          <mat-step
            [completed]="stateIndexes[video.state] >= 2"
            editable="false"
          >
            <ng-template matStepLabel>Processing</ng-template>
            <div class="flow-actions-step">
              <button
                mat-raised-button
                *ngIf="
                  auth.canProcess() && video.assignedUser === user.userId
                "
                (click)="onReleaseVideo()"
                [disabled]="isLoading || busy"
              >
                Release
                <mat-icon>person_remove</mat-icon>
              </button>
              <button
                [disabled]="!auth.canProcess() || isLoading || busy"
                mat-raised-button
                color="accent"
                (click)="onReviseVideo()"
              >
                Upload Revision<mat-icon class="btn-icon">upload</mat-icon>
              </button>
            </div>
          </mat-step>
          <mat-step
            [completed]="stateIndexes[video.state] >= 3"
            editable="false"
          >
            <ng-template matStepLabel>Pending Approval</ng-template>
            <div class="flow-actions-step">
              <button
                [disabled]="!auth.canApprove() || busy"
                mat-raised-button
                color="accent"
                (click)="onRejectRevision()"
              >
                Reject Revision<mat-icon class="btn-icon"
                  >report_problem</mat-icon
                >
              </button>
              <button
                [disabled]="!auth.canApprove() || isLoading || busy"
                mat-raised-button
                color="primary"
                (click)="onApproveRevision()"
              >
                Approve Revision<mat-icon class="btn-icon">done_all</mat-icon>
              </button>
            </div>
          </mat-step>
          <mat-step completed="false" editable="false">
            <ng-template matStepLabel>Approved</ng-template>
          </mat-step>
        </mat-stepper>
      </div>
