import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes } from 'src/app/utils/constants/routes.enum';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  backRoute: string = 'curation/videos';

  constructor(private router: Router, private activeRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activeRoute.params
      .subscribe((params) => {
        if (params) {
          this.backRoute = params.category
            ? params.category
            : 'curation/videos';
        }
      })
  }

  onNavigateHome(): void {
    this.router.navigateByUrl(this.backRoute);
  }
}
