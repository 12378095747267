import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppRoutes } from '../utils/constants/routes.enum';
import { AuthenticatedUserViewmodel } from '../viewmodels/authenticated-user.viewmodel';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private jwtHelper: JwtHelperService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.authenticatedUser.pipe(
      map((user: AuthenticatedUserViewmodel) => {
        const isAuthenticated = !this.jwtHelper.isTokenExpired();
        if (isAuthenticated) {
          return true;
        } else {
          return this.router.parseUrl(AppRoutes.Login);
        }
      })
    );
  }
}
