<div class="container" *ngIf="video">
  <mat-card style="flex: 1">
    <div class="flow-actions">
      <div>
        <button mat-icon-button (click)="onOpenInstructions()">
          <mat-icon>help</mat-icon>
        </button>
      </div>
      <app-flow-indicator
        [video]="video"
        [busy]="isLoading"
        (flowChanged)="fetchVideoDetail()"
      ></app-flow-indicator>
      <button
        #download
        mat-raised-button
        color="primary"
        (click)="onDownload()"
        [disabled]="isLoading"
      >
        Download
        <mat-icon>download</mat-icon>
      </button>
      <button
        *ngIf="auth.canDelete()"
        mat-raised-button
        color="warn"
        (click)="onDeleteVideo()"
        [disabled]="isLoading"
      >
        <mat-icon>delete_outline</mat-icon>
      </button>
    </div>
    <div class="detail-header">
      <mat-card-title>
        {{ video.name }}
      </mat-card-title>
      <mat-chip-list *ngIf="video.isExcluded" selectable="false">
        <mat-chip color="warn" selected> Excluded </mat-chip>
      </mat-chip-list>
    </div>

    <div class="flex video-pane">
      <div class="flex-left">
        <mat-tab-group [style.width.px]="maxTabWidth">
          <mat-tab *ngIf="video.src" label="Current">
            <video
              class="flex justify-center align-center"
              [ngClass]="{'scale-down': isVideoTooWide}"
              controls
              allowfullscreen
            >
              <source [src]="videoUrl" type="video/webm" />
              Your browser doesn't support embedded videos
            </video>
            <div *ngIf="video.videoBarcodeSrc" style="margin-top: 1rem;">
              <img [src]="video.videoBarcodeSrc" alt="Video Barcode">
            </div>
          </mat-tab>
          <mat-tab label="Original">
            <video
              class="flex justify-center align-center"
              [ngClass]="{'scale-down': isVideoTooWide}"
              controls
              allowfullscreen
            >
              <source [src]="videoOriginalUrl" type="video/webm" />
              Your browser doesn't support embedded videos
            </video>
          </mat-tab>
          <mat-tab label="Size Preview">
            <app-size-preview
              [resolutionHeight]="video.height"
              [resolutionWidth]="video.width"
            ></app-size-preview>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="flex-right">
        <div
          *ngIf="video.reason !== null"
          class="flex-column rejection-container"
        >
          <div class="flex-row rejection-title">
            <mat-icon>warning</mat-icon>
            <h3 style="margin: 0 0 4px">Video was rejected</h3>
          </div>
          <span
            >Reason: <i>{{ video.reason }}</i></span
          >
        </div>
        <div>
          <div>
            Created: {{ video.creationTime }}
            <app-copy-container
              [name]="'ID'"
              [data]="video.id"
            ></app-copy-container>
          </div>

          <p>
            Duration: {{ video.duration }}<br />
            Resolution: {{ video.resolution }}<br />
            Original size: {{ video.originalSize }}<br />
            Framerate: {{ video.fps }} FPS<br />
            Bitrate: {{ video.bitrate }} kbps<br />
            Codec: {{ video.codecName }}<br />
          </p>

          <app-copy-container
            [name]="'Original URL'"
            [data]="video.originalSrc"
          ></app-copy-container>

          <app-copy-container *ngIf="video.src"
            [name]="'Current URL'"
            [data]="video.src"
          ></app-copy-container>
        </div>
      </div>
    </div>
  </mat-card>

  <div>
    <mat-card class="frame-card">
      <mat-card-title>Frame Preview</mat-card-title>
      <mat-card-content>
        <div class="frame-container">
          <div *ngIf="!video.hasPreviewFrames; else previews">
            <h3>
              Please be patient while preview frames are being generated...
            </h3>
          </div>
          <ng-template #previews>
            <img
              class="frame-image"
              *ngFor="let image of video.previewFrames; let i = index"
              [src]="image"
              alt="{{ 'Preview Frame ' + i }}"
            />
          </ng-template>
        </div>
        <div>
          <button mat-stroked-button color="primary" (click)="onRegenerateFramePreviews()">Regenerate frame previews</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div style="padding-bottom: 1rem">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-description>Raw Metadata</mat-panel-description>
      </mat-expansion-panel-header>
      <ngx-json-viewer
        [json]="video.rawData"
        [expanded]="false"
      ></ngx-json-viewer>
    </mat-expansion-panel>
  </div>

  <div style="padding-bottom: 1rem">
    <mat-expansion-panel #expansionPanel (opened)="onOpenGoogleClassifierDataSection()">
      <mat-expansion-panel-header>
        <mat-panel-description>Google classifier data (raw)</mat-panel-description>
        <button
          *ngIf="expansionPanel.expanded && auth.canApprove()"
          mat-raised-button
          color="primary"
          (click)="onRegenerateData($event)"
          [disabled]="isLoading"
        >
          Regenerate Classifier Data
        </button>
        <button
          *ngIf="expansionPanel.expanded"
          mat-button
          (click)="onCopyGoogleClassifierData($event)"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
      </mat-expansion-panel-header>
      <ngx-json-viewer
        *ngIf="googleClassifierData !== undefined"
        [json]="googleClassifierData"
        [expanded]="false"
      ></ngx-json-viewer>
    </mat-expansion-panel>
  </div>
</div>
