export function formatFps(fps: number): string {
  return fps.toFixed(1);
}

export function formatDuration(length: number): string {
  if (!isNaN(length)) {
    const minutes = Math.floor(length / 60).toFixed(0);
    const seconds = Math.floor(length % 60).toFixed(0);
    const formattedLength =
      (minutes !== '0' ? `${minutes}m` : '') + `${seconds}s`;
    return formattedLength;
  } else {
    return undefined;
  }
}

