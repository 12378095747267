<h1 mat-dialog-title>Why do you reject this revision?</h1>
<div mat-dialog-content>
  <mat-form-field class="w-full" appearance="fill">
    <mat-label>Rejection Reason (min. 10 characters)</mat-label>
    <textarea [formControl]="reason" matInput type="text"></textarea>
  </mat-form-field>
</div>
<div class="space-between" mat-dialog-actions>
  <button mat-button (click)="onCancel()" cdkFocusInitial>Cancel</button>
  <button
    mat-button
    color="warn"
    [disabled]="reason.invalid"
    (click)="onRejectVideo()"
  >
    Reject!
  </button>
</div>
