import { Component, Input } from '@angular/core';
import { LabelDetailViewmodel } from 'src/app/viewmodels/label-detail.viewmodel';

@Component({
  selector: 'app-label-pill',
  templateUrl: './label-pill.component.html',
  styleUrls: ['./label-pill.component.scss'],
})
export class LabelPillComponent {

  @Input() label: LabelDetailViewmodel;

  constructor() {
  }
}
