import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserRepository {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private jwtHelper: JwtHelperService
  ) {
    this.auth.authenticatedUser.subscribe((user) => {
      if (!jwtHelper.isTokenExpired()) {
        this.headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${user.token}`
        );
      } else {
        this.headers = new HttpHeaders();
      }
    });
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(
      `${environment.backendUrl}/users`,
      {
        headers: this.headers
      }
    );
  }

  addUser(loginId: string, role: string, password: string): Observable<HttpResponse<User>> {
    let formData = new FormData();
    formData.set('user_login_id', loginId);
    formData.set('user_role', role);
    formData.set('password', password);
    return this.http.post<User>(
      `${environment.backendUrl}/users`,
      formData,
      {
        headers: this.headers,
        observe: 'response'
      }
    );
  }

  deleteUser(userId: string): Observable<any> {
    return this.http.delete(`${environment.backendUrl}/users/${userId}`, {
      headers: this.headers,
      observe: 'response',
    });
  }

  lockUser(userId: string): Observable<HttpResponse<User>> {
    return this.http.post<User>(`${environment.backendUrl}/users/${userId}/lock`,
      null,
      {
        headers: this.headers,
        observe: 'response',
      }
    );
  }

  unlockUser(userId: string): Observable<HttpResponse<User>> {
    return this.http.post<User>(`${environment.backendUrl}/users/${userId}/unlock`,
      null,
      {
        headers: this.headers,
        observe: 'response',
      })
  }

  setPassword(userId: string, password: string): Observable<HttpResponse<User>> {
    let formData = new FormData();
    formData.set('password', password)
    return this.http.post<User>(`${environment.backendUrl}/users/${userId}/password`,
      formData,
      {
        headers: this.headers,
        observe: 'response',
      })
  }
}
