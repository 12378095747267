import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


export enum MultipartUploadTarget {
  Curation = 'curation',
  Sharing = 'sharing',
  Render = 'render'
}

export interface StartMultipartUploadRequest {
  target: MultipartUploadTarget;
  filename: string;
  content_type: string;
  file_size: number;
  part_size: number;
  tags?: string[];
}

export interface StartMultipartUploadResponse {
  upload_id: string;
  bucket: string;
  key: string;
  presigned_upload_part_urls: string[];
}

export interface Part {
  part_number: number;
  etag: string;
}

export interface CompleteMultipartUploadRequest {
  bucket: string;
  upload_id: string;
  key: string;
  parts_list: Part[];
}

export interface CompleteMultipartUploadResponse {
  upload_id: string;
  key: string;
  presigned_url: string;
  request_body_base64: string;
}

export interface AbortMultipartUploadRequest {
  bucket: string;
  upload_id: string;
  key: string;
}

@Injectable({
  providedIn: 'root',
})
export class MultipartUploadService {

  constructor(private http: HttpClient) {
  }

  createMultipartUpload(target: MultipartUploadTarget, filename: string, contentType: string|undefined,
                        fileSize: number, chunkSize: number): Observable<StartMultipartUploadResponse> {
    const req: StartMultipartUploadRequest = {
      target: target,
      filename: filename,
      content_type: contentType,
      file_size: fileSize,
      part_size: chunkSize
    };
    return this.http.post<StartMultipartUploadResponse>(
      `${environment.backendUrl}/multipart-uploads/create`,
      req,
      {
        observe: 'body',
        responseType: 'json'
      }
    );
  }

  abortMultipartUpload(bucket: string, key: string, uploadId: string): Observable<any> {
    const req: AbortMultipartUploadRequest = {
      bucket,
      key,
      upload_id: uploadId
    }
    return this.http.post(
      `${environment.backendUrl}/multipart-uploads/abort`,
      req,
      {
        observe: 'body',
        responseType: 'json'
      }
    );
  }

  completeMultipartUpload(bucket: string, key: string, uploadId: string, partsList: Part[]): Observable<CompleteMultipartUploadResponse> {
    const req: CompleteMultipartUploadRequest = {
      bucket: bucket,
      upload_id: uploadId,
      key: key,
      parts_list: partsList
    };
    return this.http.post<CompleteMultipartUploadResponse>(
      `${environment.backendUrl}/multipart-uploads/complete`,
      req,
      {
        observe: 'body',
        responseType: 'json'
      }
    );
  }
}
