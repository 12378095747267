import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AddUserDialogComponent } from './dialogs/add-user-dialog/add-user-dialog.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { InstructionsDialogComponent } from './dialogs/instructions-dialog/instructions-dialog.component';
import { RejectionDialogComponent } from './dialogs/rejection-dialog/rejection-dialog.component';
import { SetPasswordDialogComponent } from './dialogs/set-password-dialog/set-password-dialog.component';
import { UploadDialogComponent } from './dialogs/upload-dialog/upload-dialog.component';
import { LabelComponent } from './label/label.component';
import { MaterialModule } from '../material/material.module';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { HoverDirective } from './directives/hover.directive';
import { CopyContainerComponent } from './copy-container/copy-container.component';
import { SizePreviewComponent } from './size-preview/size-preview.component';
import { ReviseVideoDialogComponent } from './dialogs/revise-video-dialog/revise-video-dialog.component';
import { LabelPillComponent } from './label-pill/label-pill.component';

@NgModule({
  declarations: [
    AddUserDialogComponent,
    ConfirmationDialogComponent,
    InstructionsDialogComponent,
    RejectionDialogComponent,
    SetPasswordDialogComponent,
    UploadDialogComponent,
    LabelComponent,
    LabelPillComponent,
    DragAndDropDirective,
    HoverDirective,
    CopyContainerComponent,
    SizePreviewComponent,
    ReviseVideoDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    LabelComponent,
    LabelPillComponent,
    HoverDirective,
    DragAndDropDirective,
    ConfirmationDialogComponent,
    CopyContainerComponent,
    SizePreviewComponent,
    ReviseVideoDialogComponent
  ],
})
export class SharedModule {}
