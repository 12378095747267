<div class="container flex-column justify-center align-center">
  <img
    [style.height.vh]="35"
    src="assets/img/no-content-icon.svg"
    alt="No content was found"
    style="margin: 4rem"
  />
  <h1 class="mat-display-3" style="margin-bottom: 1rem">Resource not found</h1>
  <h1 class="mat-headline">Seems like you found an empty reel</h1>
  <button mat-raised-button color="primary" (click)="onNavigateHome()">
    Go Back
  </button>
</div>
