import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserRepository } from '../../../../services/user.repository';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { availableRoles } from '../../../../utils/constants/roles';

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss'],
})
export class AddUserDialogComponent implements OnInit {

  availableRoles = availableRoles;
  busy: boolean = false;
  formGroup = new FormGroup({
    loginId: new FormControl('', [Validators.required, Validators.minLength(3)]),
    role: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)])
  })

  constructor(
    private userRepository: UserRepository,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AddUserDialogComponent>
  ) {
  }

  ngOnInit() {
    this.dialogRef.addPanelClass('dialog-panel-small');
  }

  onAddUser(): void {
    this.busy = true;
    this.userRepository.addUser(this.formGroup.value.loginId, this.formGroup.value.role, this.formGroup.value.password).subscribe({
      next: () => {
        this.snackBar.open(`Successfully added new user ${this.formGroup.value.loginId}`);
        this.busy = false;
        this.dialogRef.close(true);
      },
      error: (err: HttpErrorResponse) => {
        this.snackBar.open(`Failed to create user (${err.message})`);
        this.busy = false;
      }
    })
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
