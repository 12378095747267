<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
  <p>{{ text }}</p>
</div>
<div class="space-between" mat-dialog-actions>
  <button mat-button (click)="onCancel()" cdkFocusInitial>
    Cancel
  </button>
  <button mat-raised-button color="warn" (click)="onConfirm()">
    Confirm
  </button>
</div>
