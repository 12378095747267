const MAX_FRAME_WIDTH =
  (window.innerWidth / 2) % 2 === 0
    ? window.innerWidth / 2
    : (window.innerWidth - 1) / 2;
const MAX_PREVIEW_WIDTH = 500;

export {
  MAX_FRAME_WIDTH,
  MAX_PREVIEW_WIDTH,
};
