import { Injectable } from '@angular/core';
import pkg from '../../../package.json'

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  private readonly version: string;
  private readonly gitHash: string;

  constructor() {
    this.version = pkg.version;
    this.gitHash = pkg.ch_pixelverse.gitHash ?? '';
    console.log(`CUBE Curator version: ${this.version} (${this.gitHash})`);
  }

  appVersion(): string {
    return `${this.version} (${this.gitHash})`;
  }
}
