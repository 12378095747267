import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-copy-container',
  templateUrl: './copy-container.component.html',
  styleUrls: ['./copy-container.component.scss'],
})
export class CopyContainerComponent {
  @Input() data: string;
  @Input() name: string;

  constructor(private clipboard: Clipboard, private snackBar: MatSnackBar) {}

  onCopyItem(item: string): void {
    this.clipboard.copy(item);
    this.snackBar.open(`Copied \'${item}\' to clipboard`);
  }

  truncateString(str: string, maxLen: number): string {
    return str.length > maxLen ? str.substring(0, maxLen - 1) + '...' : str;
  }
}
