import {
  HttpErrorResponse,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FileUploadViewModel } from 'src/app/viewmodels/file-upload-view-model';
import { VideoErrorResponse } from 'src/app/models/video-error-response.model';
import { VideoService } from 'src/app/services/video.service';
import { VideoViewModel } from 'src/app/viewmodels/videoViewModel';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
})
export class UploadDialogComponent implements OnInit {
  @ViewChild('fileUploadDialog', { static: true }) fileUpload: ElementRef;
  videoName: string;
  videoUrl: string;

  uploadFile: FileUploadViewModel;
  isUploading: boolean = false;

  errorMessage: string;
  rejectionReasons: string[] = [];

  constructor(
    private dialogRef: MatDialogRef<UploadDialogComponent>,
    private videoService: VideoService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: VideoViewModel
  ) {}

  ngOnInit(): void {
    this.fileUpload.nativeElement.addEventListener('change', () => {
      const fileUpload = this.fileUpload.nativeElement;
      const file = fileUpload.files[0];
      this.uploadFile = { data: file, inProgress: false, progress: 0 };
      this.onUpload(this.uploadFile);
    });
  }

  onFileDropped(files): void {
    if (files.length === 1 && !this.isUploading) {
      this.uploadFile = { data: files[0], inProgress: false, progress: 0 };
      this.onUpload(this.uploadFile);
    } else if (this.isUploading) {
      this.snackBar.open(
        'Upload already running, please wait until the current upload is done.'
      );
    } else {
      this.snackBar.open('Upload for multiple files is not supported');
    }
  }

  onOpenFile(): void {
    this.fileUpload.nativeElement.value = '';
    this.fileUpload.nativeElement.disabled = false;
    this.fileUpload.nativeElement.click();
    this.fileUpload.nativeElement.disabled = true;
  }

  onUpload(file: FileUploadViewModel): void {
    this.isUploading = true;
    this.dialogRef.disableClose = true;
    const formData = new FormData();
    formData.append('file', file.data);
    file.inProgress = true;
    this.videoService
      .reviseVideo(this.data.id, formData)
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              return file.progress;
            case HttpEventType.Response:
              return event;
            default:
              return null;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          file.progress = -1;
          return of(error);
        })
      )
      .subscribe((event) => {
        if (typeof event === 'object') {
          if (event instanceof HttpResponse && event.status === 200) {
            file.inProgress = false;
            file.inProgress = false;
            this.dialogRef.close(true);
            this.snackBar.open('Revision uploaded successfully');
          } else if (event instanceof HttpErrorResponse) {
            const eventMessage = event as VideoErrorResponse;
            this.errorMessage = eventMessage.error.message;
            this.rejectionReasons = eventMessage.error.failed_checks;
            this.dialogRef.disableClose = false;
            this.isUploading = false;
          }
        }
      });
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
