<div class="container" [ngClass]="{ 'h-adjusted': !hasStagedFiles }">
  <mat-card [ngClass]="{ 'h-adjusted': !hasStagedFiles }">
    <div
      class="dropzone"
      [ngClass]="{ flex: !hasStagedFiles }"
      [hidden]="hasStagedFiles"
    >
      <input
        type="file"
        appDragAndDrop
        (fileDropped)="onFileDropped($event)"
        #fileUpload
        id="fileUpload"
        name="fileUpload"
        multiple="multiple"
        accept="video/*"
        [disabled]="true"
      />
      <span
        >Drag and drop your video(s) here or<br />
        <button
          mat-raised-button
          color="primary"
          class="display-top"
          (click)="onOpenFile()"
        >
          browse files
        </button>
      </span>
    </div>
    <mat-card-content *ngIf="hasStagedFiles && filesStaged.length > 0">
      <div>
        <mat-card-title class="flex file-container">
          Staged files:
          <button
            mat-raised-button
            color="primary"
            [disabled]="filesStaged.length === 0"
            (click)="onUploadFiles()"
          >
            Start upload
            <mat-icon>file_upload</mat-icon>
          </button>
        </mat-card-title>
        <div class="checkbox checkbox-container">
          <span class="checkbox checkbox-filled">
            <mat-checkbox [(ngModel)]="isExcluded" color="warn"
              >Exclude from curation</mat-checkbox
            >
          </span>
        </div>
        <ul class="list-none">
          <li class="list-item" *ngFor="let file of filesStaged; let i = index">
            <mat-card>
              <div class="flex file-container">
                <span>
                  {{ file.data.name }} <br />
                  <span class="mat-caption">{{
                    formatFileSize(file.data.size)
                  }}</span>
                </span>
                <div class="flex">
                  <button mat-button color="warn" (click)="onRemoveFile(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </mat-card>
          </li>
        </ul>
        <div *ngIf="filesStaged.length > 15" class="flex flex-right">
          <button
            mat-raised-button
            color="primary"
            [disabled]="filesStaged.length === 0"
            (click)="onUploadFiles()"
          >
            Start upload
            <mat-icon>file_upload</mat-icon>
          </button>
        </div>
      </div>
    </mat-card-content>
    <mat-card-content *ngIf="isUploadStarted">
      <div *ngIf="filesDuplicated.length !== 0">
        <mat-card-title>Duplicates:</mat-card-title>
        <mat-card-subtitle>
          Videos must have unique names and duplicates are not allowed
        </mat-card-subtitle>
        <ul>
          <li class="list-item" *ngFor="let file of filesDuplicated">
            <div class="flex file-container">
              <span>
                {{ file.data.name }} <br />
                <span class="mat-caption">{{
                  formatFileSize(file.data.size)
                }}</span>
              </span>
              <div class="flex">
                <mat-icon>warning</mat-icon>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div *ngIf="filesFailed.length !== 0">
        <mat-card-title>Failed:</mat-card-title>
        <ul>
          <li class="list-item" *ngFor="let file of filesFailed">
            <div class="flex file-container">
              <span>
                {{ file.data.name }} <br />
                <span class="mat-caption">{{
                  formatFileSize(file.data.size)
                }}</span>
              </span>
              <div class="flex">
                <button mat-button color="primary" (click)="onUploadFile(file)">
                  <mat-icon>refresh</mat-icon>
                  Retry
                </button>
                <mat-icon>error</mat-icon>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div *ngIf="filesInProgress.length !== 0">
        <mat-card-title>Files Pending:</mat-card-title>
        <ul>
          <li class="list-item" *ngFor="let file of filesInProgress">
            <div class="flex file-container">
              <span>{{ file.data.name }}</span>
              <mat-icon>pending</mat-icon>
            </div>
            <mat-progress-bar [value]="file.progress"></mat-progress-bar>
          </li>
        </ul>
      </div>
      <div *ngIf="uploadedFiles.length !== 0">
        <mat-card-title>Uploaded Successfully:</mat-card-title>
        <ul>
          <li
            class="list-item"
            (click)="onNavigateDetail(file.id)"
            *ngFor="let file of uploadedFiles"
          >
            <div class="flex file-container">
              <span class="link">
                {{ file.data.name }} <br />
                <span class="mat-caption">{{
                  formatFileSize(file.data.size)
                }}</span>
              </span>
              <mat-icon>check</mat-icon>
            </div>
          </li>
        </ul>
      </div>
      <div class="flex flex-right">
        <button
          [disabled]="filesInProgress.length !== 0 || filesFailed.length !== 0"
          mat-button
          (click)="onResetState()"
        >
          Select more files
        </button>
        <button
          [disabled]="filesInProgress.length !== 0 || filesFailed.length !== 0"
          mat-raised-button
          color="primary"
          (click)="onNavigateHome()"
        >
          Go Back
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
