import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { VideoService } from 'src/app/services/video.service';
import { catchError, map } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import { of } from 'rxjs';
import { FileUploadViewModel } from 'src/app/viewmodels/file-upload-view-model';
import { Router } from '@angular/router';
import { ApiRoutes } from 'src/app/utils/constants/api.enum';
import { formatSize } from 'src/app/utils/size-util';

@Component({
  selector: 'app-video-upload',
  templateUrl: './video-upload.component.html',
  styleUrls: ['./video-upload.component.scss'],
})
export class VideoUploadComponent implements OnInit {
  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef;
  files: FileUploadViewModel[] = [];
  get filesStaged(): FileUploadViewModel[] {
    return this.files.filter((f) => !f.inProgress && f.progress === 0);
  }
  get filesInProgress(): FileUploadViewModel[] {
    return this.files.filter((f) => f.inProgress);
  }
  get filesFailed(): FileUploadViewModel[] {
    return this.files.filter((f) => !f.inProgress && f.progress === -1);
  }
  get filesDuplicated(): FileUploadViewModel[] {
    return this.files.filter((f) => !f.inProgress && f.progress === -2);
  }
  uploadedFiles: FileUploadViewModel[] = [];
  hasStagedFiles: boolean = false;
  isUploadStarted: boolean = false;

  isExcluded: boolean = false;

  private isSingleFile = false;

  constructor(private uploadService: VideoService, private router: Router) {}

  ngOnInit(): void {
    this.fileUpload.nativeElement.addEventListener('change', () => {
      const fileUpload = this.fileUpload.nativeElement;
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.hasStagedFiles = true;
    });
  }

  onUploadFile(file: FileUploadViewModel) {
    const formData = new FormData();
    formData.append('file', file.data);
    formData.append('excluded_from_curation', this.isExcluded.toString());
    file.inProgress = true;
    this.uploadService
      .upload(formData)
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              return file.progress;
            case HttpEventType.Response:
              return event;
            default:
              return null;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 409) {
            file.progress = -2;
            file.inProgress = false;
          } else {
            file.progress = -1;
            file.inProgress = false;
          }
          return of(`${file.data.name} upload failed.`);
        })
      )
      .subscribe((event) => {
        if (typeof event === 'object') {
          if (event instanceof HttpResponse && event.status === 200) {
            file.inProgress = false;
            file.id = event.body.video_id;
            this.uploadedFiles.push(file);
            if (this.isSingleFile) {
              this.onNavigateDetail(event.body.video_id);
            }
          }
        }
      });
  }

  onRemoveFile(fileIndex: number): void {
    if (this.files.length === 1) {
      this.hasStagedFiles = false;
    }
    this.files.splice(fileIndex, 1);
  }

  onFileDropped(files: File[]): void {
    if (files.length >= 1) {
      for (let file of files) {
        this.files.push({ data: file, inProgress: false, progress: 0 });
      }
      this.hasStagedFiles = true;
    }
  }

  onOpenFile(): void {
    this.fileUpload.nativeElement.disabled = false;
    this.fileUpload.nativeElement.click();
    this.fileUpload.nativeElement.disabled = true;
  }

  onNavigateDetail(id: string): void {
    this.router.navigate([`${ApiRoutes.CURATION}/${id}`]);
  }

  onNavigateHome(): void {
    this.router.navigate([`${ApiRoutes.CURATION}`]);
  }

  onResetState(): void {
    this.fileUpload.nativeElement.value = '';
    this.files = [];
    this.uploadedFiles = [];
    this.hasStagedFiles = false;
    this.isUploadStarted = false;
  }

  onUploadFiles() {
    this.fileUpload.nativeElement.value = '';
    this.isUploadStarted = true;
    if (this.files.length === 1) {
      this.isSingleFile = true;
      this.isEligibleForUpload(this.files[0]);
    } else {
      this.files.forEach((file) => {
        this.isSingleFile = false;
        this.isEligibleForUpload(file);
      });
    }
  }

  formatFileSize(size: number): string {
    return formatSize(size);
  }

  private isEligibleForUpload(file: FileUploadViewModel) {
    file.inProgress = true;
    if (
      !this.uploadedFiles.includes(file) &&
      this.getLenghtOfAllUploadedFiles() === this.files.indexOf(file)
    ) {
      this.onUploadFile(file);
    } else {
      setTimeout(() => this.isEligibleForUpload(file), 500);
    }
  }

  private getLenghtOfAllUploadedFiles(): number {
    return (
      this.uploadedFiles.length +
      this.filesFailed.length +
      this.filesDuplicated.length
    );
  }
}
